import React from 'react'

const Contacts = () => {
  return (
    <>
      <div className="h-screen items-center flex justify-center text-4xl text-gray-400">
        <h1>Contact</h1>
      </div>
    </>
  )
}

export default Contacts
