import React from 'react'

const Join = () => {
  return (
    <>
      <div className="h-screen items-center flex justify-center text-4xl text-gray-400">
        <h1>Join</h1>
      </div>
    </>
  )
}

export default Join
