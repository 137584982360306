import React from "react";

const About = () => {
  return (
    <>
      <div className="h-screen items-center flex justify-center text-4xl text-gray-400">
        <h1>About</h1>
      </div>
    </>
  );
};

export default About;
